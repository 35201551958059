import { Component } from '@angular/core';
import {NavigationService} from '../../../services/navigation.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent  {
  showMonkeySurvey: boolean;
  userId:any;
  isPreview:boolean;
  title:any;
  editorData:any;
  isShowAncmt:any;
  isAnnouncementClosed:any;
  constructor(public navigationService : NavigationService) { 
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers About');
    let isLogin = localStorage.getItem('userInfo');
    if(isLogin){
      this.getUserInfo();
    }
  }

  getUserInfo(){
    this.navigationService.getUserInfo().subscribe((res: any) => {
      this.isShowAncmt = localStorage.getItem('show_announcement');
      this.isAnnouncementClosed = res.data.isAnnouncementClosed;
        if (res?.data?.surveyOpenCount < 3 && !this.navigationService.isSurveyMonkey && !res?.data?.isSurveySubmitted) {
          let isStoltDomain = res.data.email.toLowerCase().includes('@stolt');
          let isSuperAdmin = res.data.role === 'super_admin';
          if (isStoltDomain && isSuperAdmin || !isStoltDomain) {
            this.navigationService.isSurveyMonkey = true;
            this.showMonkeySurvey = true;
            this.userId = res.data.user_id;
          } else{
            this.openAnnouncement();
          }
        }else{
          this.openAnnouncement();
        }
    })
  }

  openAnnouncement(){
    if (this.isShowAncmt || (!this.isShowAncmt && !this.isAnnouncementClosed)) {
      this.getActiveAnnouncement();
    }
  }

  onSurveyClosed(value: string) {
    if(value){
      this.openAnnouncement();
    }
  }

  getActiveAnnouncement(){
    this.navigationService.getActiveAnnouncement().subscribe((res: any) => {
       if(res.status == 200){
        this.title = res.data.title;
        this.editorData = res.data.content;
        this.isPreview = true;
       }
    })
  }
}
