import { Component, OnInit } from '@angular/core';
import { ContactData } from "../../../models/publicPages";
import { NavigationService } from '../../../services/navigation.service';
import { PublicPagesService } from '../../../services/public-pages.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactRegion = 'All Regions';
  contactJob = 'All functions';
  contactList: ContactData[] = [];
  contactListData: ContactData[] = [];
  contactJobData: any[];
  contactRegionData: any[];
  contactRegionDataFilter: any[];
  isSkelton = false;
  pageTrackingData:any = {}
  isLogedin: Observable<boolean>;
  showMonkeySurvey: boolean;
  userId:any;
  isPreview:boolean;
  title:any;
  editorData:any;
  isShowAncmt:any;
  isAnnouncementClosed:any;
  constructor(public navigationService: NavigationService,
    public publicPagesService: PublicPagesService) {
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers Contact');
    let isLogin = localStorage.getItem('userInfo');
    if(isLogin){
      this.getUserInfo();
    }
  }

  ngOnInit(): void {
    this.isSkelton = true;
    this.publicPagesService.getContactList().subscribe({
      next:res=>{
        if (res && res.data) {
          this.isSkelton = false;
          this.contactList = res.data;
          this.contactJobData = ['All functions', ...this.contactList.map(item => item.operation_type)
            .filter((value, index, self) => self.indexOf(value) === index)]
          this.contactRegionData = ['All Regions', ...this.contactList.map(item => item.region)
            .filter((value, index, self) => self.indexOf(value) === index)];
          this.contactListData = this.contactList;
          this.contactRegionDataFilter = this.contactRegionData;
            }
      },
      error:error=>{
      this.isSkelton = false;
      this.navigationService.errorOccure(error);
      }
    })
  }

  getUserInfo(){
    this.navigationService.getUserInfo().subscribe((res: any) => {
      this.isShowAncmt = localStorage.getItem('show_announcement');
      this.isAnnouncementClosed = res.data.isAnnouncementClosed;
        if (res?.data?.surveyOpenCount < 3 && !this.navigationService.isSurveyMonkey && !res?.data?.isSurveySubmitted) {
          let isStoltDomain = res.data.email.toLowerCase().includes('@stolt');
          let isSuperAdmin = res.data.role === 'super_admin';
          if (isStoltDomain && isSuperAdmin || !isStoltDomain) {
            this.navigationService.isSurveyMonkey = true;
            this.showMonkeySurvey = true;
            this.userId = res.data.user_id;
          } else{
            this.openAnnouncement();
          }
        }else{
          this.openAnnouncement();
        }
    })
  }

  openAnnouncement(){
    if (this.isShowAncmt || (!this.isShowAncmt && !this.isAnnouncementClosed)) {
      this.getActiveAnnouncement();
    }
  }

  onSurveyClosed(value: string) {
    if(value){
      this.openAnnouncement();
    }
  }

  getActiveAnnouncement(){
    this.navigationService.getActiveAnnouncement().subscribe((res: any) => {
       if(res.status == 200){
        this.title = res.data.title;
        this.editorData = res.data.content;
        this.isPreview = true;
       }
    })
  }

  clearFilter() {
    this.contactRegion = 'All Regions';
    this.contactJob = 'All functions';
    this.jobAndRegionChanged();
  }

  filterChnaged() {
    this.jobAndRegionChanged();
  }

  jobAndRegionChanged() {
    const modifyRegion = this.contactRegion !== 'All Regions' ? this.contactList.filter(it => it.region == this.contactRegion) : this.contactList;
    const modifyJob = this.contactJob !== 'All functions' ? modifyRegion.filter(it => it.operation_type == this.contactJob) : modifyRegion;
    this.contactListData = modifyJob;
    let filteredRegion = ['All Regions', ...this.contactList.filter(it => it.operation_type == this.contactJob || it.region == 'All Regions').map(it => it.region)];
    this.contactRegionDataFilter = this.contactJob !== 'All functions' ? filteredRegion.filter((value: any, index: number, self: any) => self.indexOf(value) === index) : ['All Regions', ...this.contactList.map(item => item.region)
      .filter((value, index, self) => self.indexOf(value) === index)];
  }
  
 
}
