
<div class="StoltWrapper">
    <!-- Header Section: This section represents the header of the webpage -->
    <app-about-header></app-about-header>
    
    <main class="StoltMainContent">
        <!-- Common Hero Area -->
        <div class="CommonHeroArea ContactTitleArea position-relative">
            <div class="LargeWindowWidth">
                <div class="CommonHeroContent">
                    <h1 class="heading01">Contact</h1>
                </div>
            </div>
        </div>

        <section class="CommonContentSec">
            <div class="LargeWindowWidth MobilePosition">
                <div class="SecInner">
                    <div class="TextContent MobileWindow">
                        <!-- Total contacts & Now displaying -->
                        <div class="BoxTopInfo">
                            <ul class="BoxTopInfoInner">
                                <li [ngClass]="{'heading03':true,'Stolt-Skeleton':isSkelton}">
                                    <h3 class="heading03" *ngIf="!isSkelton">Total contacts</h3>
                                </li>

                                <li [ngClass]="{'heading04':true,'Stolt-Skeleton':isSkelton}">
                                    <h4 class="heading04" *ngIf="!isSkelton">{{contactList?.length}}</h4>
                                </li> 
                            </ul>

                            <ul class="BoxTopInfoInner InActive">
                                <li [ngClass]="{'heading03':true,'Stolt-Skeleton':isSkelton}">
                                    <h3 class="heading03" *ngIf="!isSkelton">Now displaying</h3>
                                </li>

                                <li [ngClass]="{'heading04':true,'Stolt-Skeleton':isSkelton}">
                                    <h4 class="heading04" *ngIf="!isSkelton">{{contactListData?.length}}</h4>
                                </li>  
                            </ul>
                        </div>
                        
                        <div class="FilterBxLightGrey" *ngIf="!isSkelton">
                            <h3 class="heading03" (click)="clearFilter()">Filters <a href="javascript:void(0)">CLEAR
                                    FILTERS</a></h3>
                            <div class="FieldsArea">
                                <div class="FieldsInner">
                                    <label>Function</label>
                                    <ng-select class="ng-select-fade ng-select-large ng-select-md scroll-hostSpace ng-select-width-100 ConatctFtr" [searchable]="true" [clearable]="true"
                                        [(ngModel)]="contactJob" (change)="filterChnaged()">
                                        <ng-option *ngFor="let el of contactJobData" [value]="el">{{el}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="FieldsInner">
                                    <label>Region</label>
                                    <ng-select class="ng-select-fade ng-select-large scroll-hostSpace ng-select-width-100 ConatctFtr" [searchable]="true" [clearable]="true"
                                        [(ngModel)]="contactRegion" (change)="filterChnaged()">
                                        <ng-option *ngFor="let el of contactRegionDataFilter" [value]="el">{{el|titlecase}}</ng-option>
                                    </ng-select>
                                </div>
                            </div> 
                        </div>
                        <div class="queriesTxt font-16">
                            <p>For any queries or assistance with this website, please write</p>
                            <p> to <a class="text-decoration-underline" href="mailto:stdigital-itsupport@stolt.com?subject=Email received from ST Customer Portal">stdigital-itsupport@stolt.com</a>
                            </p>
                        </div>

                        <!-- Column Content Stolt Skeleton Start -->
                        <div class="FilterBxLightGrey Stolt-SkeletonBx" *ngIf="isSkelton">
                            <div class="Stolt-Skeleton"></div>
                        </div> 
                    </div>


                    <div class="ImgArea ImgOverlap ImgOverlapM" *ngIf="!isSkelton">
                        <img class="" src="assets/img/public/stoltworker04.jpg" alt="Stolt workers image">
                        <div class="circle-effect-hloder">
                            <img class="circle-effect" src="assets/svg/public/circle-effect.svg" alt="Circle Effect">
                        </div>
                    </div>

                    <!-- Column Content Stolt Skeleton Start -->
                    <div class="ImgArea ImgOverlap ImgOverlapM SkeletonImgBx" *ngIf="isSkelton">
                        <div class="Stolt-Skeleton"></div>
                    </div> 
                </div>
            </div>
        </section>

        <!-- Section Content Global Business And Commercial -->
        <section class="CommonContentSec AdressInfoBxMain" *ngIf="!isSkelton">
            <div>
                <div class="LargeWindowWidth" *ngFor="let item of contactListData;index as i">
                    <div class="AdressInfoBx">
                        <h4 class="heading04" *ngIf="item.region !== contactListData[i-1]?.region">
                            {{item.region|titlecase }}
                        </h4>
                        <div class="InnerBxAdress">
                            <div class="InnerLeftCnt">
                                <h5 class="heading05">{{item.employeeName|titlecase}}</h5>
                                <p>{{item.operation_type}}
                                    <em>|</em>
                                    <a class="CursorDefault" *ngIf="item.office_address">{{item.office_address|titlecase}}</a>
                                </p>
                            </div>
                            <div class="InnerRightCnt">
                                <p *ngIf="item.phone">
                                    <a class="linkstg" href="mailto:{{item.email}}?subject=Email received from ST Customer Portal">
                                        <svg-icon class="mailIcn" name="mail-icon"></svg-icon>{{item.email}}
                                    </a>
                                </p>
                                <p *ngIf="item.phone">
                                     <a href="tel:{{item.phone}}">
                                        <svg-icon name="phone-icon"></svg-icon>
                                        {{item.phone}}
                                    </a>
                                </p>
                                <p *ngIf="item.mobile">
                                    <a href="tel:{{item.mobile}}">
                                        <svg-icon name="mob-icon"></svg-icon>
                                        {{item.mobile}}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="DataNotAvailable mt-2" *ngIf="contactListData.length ===0">
                    <div class="LargeWindowWidth text-center"><span>No Item Found!</span></div>
                </div>
            </div>
        </section> 

        <!-- Section Content Stolt Skeleton Start -->
        <section class="CommonContentSec AdressInfoBxMain" *ngIf="isSkelton">
            <div>
                <div class="LargeWindowWidth">
                    <div class="AdressInfoBx">
                        <h4 class="heading04 Stolt-Skeleton Custom-w-50"></h4> 
                        <div class="InnerBxAdress" *ngFor="let number of [0,1,2,3,4,5]">
                            <div class="InnerLeftCnt">
                                <h5 class="heading05 Stolt-Skeleton Custom-w-50"></h5>
                                <p class="Stolt-Skeleton Custom-w-70"></p>
                                <p class="Stolt-Skeleton Custom-w-80"></p>
                            </div>
                            <div class="InnerRightCnt">
                                <p class="Stolt-Skeleton"></p>
                                <p class="Stolt-Skeleton"></p>
                                <p class="Stolt-Skeleton"></p>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </section> 
    </main>
    <app-footer></app-footer>
    <app-scroll-to-top></app-scroll-to-top>
</div>
<app-show-announcement *ngIf="isPreview" [title]="title" [editorData]="editorData" [isPreview]="isPreview" (closePopupEmit)="isPreview = false"></app-show-announcement>
<app-nps-survey-monkey *ngIf="showMonkeySurvey" [userId]="userId" (surveyClosed)="onSurveyClosed($event)"></app-nps-survey-monkey>
